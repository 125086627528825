export const DASHBOARD = "/";
export const SIGN_IN = "/login";
export const JOBS = "/jobs";
export const JOBS_DETAIL = "/jobs/[id]";
export const JOBS_DETAIL_EDIT = "/jobs/[id]/edit";
export const JOBS_DETAIL_ACTIVITY = "/jobs/[id]/activity";
export const JOBS_DETAIL_ISSUES = "/jobs/[id]/issues";
export const JOBS_DETAIL_STOCK = "/jobs/[id]/stock";
export const JOBS_DETAIL_JOB_SHEETS = "/jobs/[id]/job-sheets";
export const JOBS_DETAIL_COMPLAINTS = "/jobs/[id]/complaints";
export const JOBS_ADD = "/jobs/add";
export const CLIENTS = "/clients";
export const CLIENTS_DETAIL = "/clients/[id]";
export const CLIENTS_UPDATE = "/clients/[id]/update";
export const CLIENTS_ADD = "/clients/add";
export const STAFF = "/staff";
export const STAFF_DETAIL = "/staff/[id]";
export const STAFF_UPDATE_ROLE = "/staff/[id]/role";
export const STAFF_ADD = "/staff/add";
export const TODO = "/todo";
export const TODO_TEMPLATE = "/todo/template";
export const TODO_TEMPLATE_ADD = "/todo/template/add";
export const TODO_TEMPLATE_DETAIL = "/todo/template/[id]";
export const TODO_TEMPLATE_UPDATE = "/todo/template/[id]/update";
export const DATELINE = "/jobs/dateline";
export const DATELINE_TEMPLATE = "/jobs/dateline/template";
export const DATELINE_TEMPLATE_ADD = "/jobs/dateline/template/add";
export const DATELINE_TEMPLATE_DETAIL = "/jobs/dateline/template/[id]";
export const DATELINE_TEMPLATE_UPDATE = "/jobs/dateline/template/[id]/update";
