import { useEffect, useContext } from "react";
import { validateToken } from "../services/HttpServices";
import { LoginStateContext } from "../context/Login/LoginState";
import { queryCache } from "react-query";
import Router from "next/router";

export const useFetchLocalStorage = () => {
  const {
    user: userState,
    loginUser,
    loadingUser,
    finishLoadingUser,
  } = useContext(LoginStateContext);

  useEffect(
    function fetchLocalStorage() {
      const token = localStorage.getItem("token");
      if (!userState.isLoggedIn) {
        if (token) {
          loadingUser();
          validateToken(token)
            .then(() => {
              // Router.push("/");
              loginUser(token);
              // queryCache.refetchQueries("current-user");
              finishLoadingUser();
            })
            .catch((e) => {
              console.error(e.message);
              finishLoadingUser();
              Router.push("/login");
            });
        } else {
          Router.push("/login");
        }
      }
    },
    [userState.token]
  );
};
