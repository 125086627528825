import Head from "next/head";
import styled, { css } from "styled-components";
import { Skeleton, Row, Col, Statistic, Button, Empty } from "antd";
import { LikeOutlined, PrinterOutlined } from "@ant-design/icons";
import { useFetchLocalStorage } from "../lib/useFetchLocalStorage";
import * as ROUTES from "../constants/routes";
import useUser from "../lib/useUser";
import SectionTitle from "../components/shared/SectionTitle";
import React from "react";

function Index() {
  useFetchLocalStorage();
  const { user, status } = useUser({ redirectTo: "/login" });
  if (status === "loading" || !user) return <Skeleton active />;

  return (
    <>
      <Head>
        <title>Dashboard - Tihani</title>
      </Head>
      <>
        <StyledSection>
          <Row gutter={16}>
            <Col span={8}>
              <StyledContainer>
                <div className="header">
                  <Statistic
                    title="Feedback"
                    value={1128}
                    prefix={<LikeOutlined />}
                  />
                  <Button type="link" block>
                    Add Job
                  </Button>
                </div>
              </StyledContainer>
            </Col>
            <Col span={8}>
              <StyledContainer>
                <div className="header">
                  <Statistic
                    title="Problem / Issues"
                    value={12}
                    valueStyle={{ color: "#cf1322" }}
                  />
                  <Button type="link" block>
                    Report Problem
                  </Button>
                </div>
              </StyledContainer>
            </Col>
            <Col span={8}>
              <StyledContainer>
                <div className="header">
                  <Statistic
                    title="Machines"
                    value={"medium"}
                    valueStyle={{ color: "#d69f2e" }}
                    prefix={<PrinterOutlined />}
                  />
                </div>
              </StyledContainer>
            </Col>
          </Row>
        </StyledSection>
        <SectionTitle title="Current User" />
        <StyledContainer $noHeight>
          <Empty />
        </StyledContainer>

        <SectionTitle title="Unresolved Issues" mt />
        <StyledContainer $noHeight>
          <Empty />
        </StyledContainer>
      </>
    </>
  );
}

const StyledSection = styled.section`
  margin-bottom: 40px;
`;

const StyledContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  height: 100%;
  padding: 32px 0;
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.grey[400]};
  box-shadow: 3px 4px 0px 0px ${(props) => props.theme.colors.grey[400]};
  ${(props) =>
    props.$noHeight &&
    css`
      height: inherit;
    `};

  .header {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      text-align: center;
    }

    .ant-statistic {
      text-align: center;
    }
  }
`;

export default Index;
